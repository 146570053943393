<template>
  <fb-page>
    <!-- 头部插槽 -->
    <fb-header slot="header"
               :title="title"
               fixed>
      <fb-button slot="left"
                 icon="back"
                 @click="$router.back()"
                 size="small"
                 type="primary">
        返回
      </fb-button>
      <fb-button slot="right"
                 size="small"
                 type="primary"
                 @click="saveOrganInfo">
        保存
      </fb-button>
    </fb-header>
    <div class="fb-organ">
      <p class="organ-entrance">
        <label class="label-entrance">机构信息</label>
      </p>
      <p class="organ-key-value"
         v-for="(organValue, organKey) in organChineseMap"
         :key="organKey">
        <label class="label-key">{{ organChineseMap[organKey] }}</label>
        <label class="label-value">
          <input type="text"
                 class="input-value"
                 v-model="organInfo[organKey]"
                 v-if="!notEditedInfos.includes(organKey)"
                 @blur="showCourtInfo(organKey)">
          <label class="input-value"
                 v-else>{{ organInfo[organKey] }}</label>
        </label>
      </p>
      <van-popup v-model="isShowCourtsDialog"
                 position="bottom"
                 class="court-dialog">
        <p class="court-type"
           v-for="(court, courtIndex) in courts"
           :key=courtIndex
           @click="chooseCourt(court)">{{ court.name }}</p>
      </van-popup>
    </div>
  </fb-page>
</template>

<script>
import { caseBase } from '~api-config'

export default {
  name: 'summary-organ',
  data () {
    return {
      title: '基本信息',
      notEditedInfos: [
        'courtTelephone',
        'courtWebSite',
        'courtAddress',
        'trialGrade'
      ],
      organInfo: {},
      caseId: 0,
      organChineseMap: {
        // presidingJudge: '审判长',
        // judge: '审判员',
        // clerk: '书记员',
        courtName: '法院名称',
        courtTelephone: '法院电话',
        courtWebSite: '法院网址',
        courtAddress: '法院地址',
        trialGrade: '审理程序'
        // onlineCreatCase: '网上立案',
        // creatCaseTime: '立案时间',
        // acceptCaseTime: '受案时间',
        // hearTime: '审理时间',
        // proofTime: '举证时间',
        // holdCourtTime: '开庭时间',
        // pronouncementTime: '宣判时间',
        // preservationStartTime: '保全开始时间'
      },
      isShowCourtsDialog: false,
      courts: []
    }
  },
  created () {
    this.organInfo = this.$route.params.organInfo
    this.caseId = this.$route.params.caseId
  },
  methods: {
    saveOrganInfo () {
      // todo 下面这行是为了测试，正式环境需要删除
      // this.caseId = 82
      this.$axios
        .put(
          caseBase + '/lts/inf/updateOfficesInf',
          Object.assign(this.organInfo, { caseId: this.caseId })
        )
        .then(res => {
          if (res.data.code === '200') {
            this.$router.back()
          }
          console.log('saveOrganInfo', res.data)
        })
    },
    showCourtInfo (organKey) {
      if (organKey !== 'courtName') {
        return false
      }

      this.$axios
        .get(caseBase + '/fabun/institution/search', {
          params: {
            name: this.organInfo[organKey]
          }
        })
        .then(res => {
          console.log(res.data.datas.datas)
          this.isShowCourtsDialog = true
          this.courts = res.data.datas.datas
        })
    },
    chooseCourt (court) {
      this.isShowCourtsDialog = false
      this.organInfo.courtName = court.name
      this.organInfo.courtTelephone = court.phone
      this.organInfo.courtWebSite = court.homepage
      this.organInfo.courtAddress = court.address
    }
  }
}
</script>

<style lang="stylus" scoped>
.fb-organ
  text-align center
  width 90%
  margin 10px auto
  border-radius 5px
  background white
  font-size 15px
  box-shadow 0px 0px 0px 1px #DCF0FA
  .organ-entrance
    color #169BD5
    overflow hidden
    height 40px
    .label-entrance
      float left
      line-height 40px
      margin-left 10px
    .iconfont
      float right
      line-height 40px
      margin-right 10px
  .organ-key-value
    overflow hidden
    height 40px
    border-top 1px solid #80808024
    .label-key
      float left
      line-height 40px
      margin-left 10px
    .label-value
      float right
      line-height 40px
      margin-right 10px
      .input-value
        border none
        text-align right
        width 200px
      .iconfont
        color #169BD5
  .court-dialog
    .court-type
      height 40px
      font-size 20px
      text-align center
    .court-type:visited
      color #169BD5
    .court-type:hover
      color #169BD5
    .court-type:active
      color #169BD5
</style>
