var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: _vm.title, fixed: "" },
          slot: "header",
        },
        [
          _c(
            "fb-button",
            {
              attrs: {
                slot: "left",
                icon: "back",
                size: "small",
                type: "primary",
              },
              on: {
                click: function ($event) {
                  return _vm.$router.back()
                },
              },
              slot: "left",
            },
            [_vm._v(" 返回 ")]
          ),
          _c(
            "fb-button",
            {
              attrs: { slot: "right", size: "small", type: "primary" },
              on: { click: _vm.saveOrganInfo },
              slot: "right",
            },
            [_vm._v(" 保存 ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "fb-organ" },
        [
          _c("p", { staticClass: "organ-entrance" }, [
            _c("label", { staticClass: "label-entrance" }, [
              _vm._v("机构信息"),
            ]),
          ]),
          _vm._l(_vm.organChineseMap, function (organValue, organKey) {
            return _c("p", { key: organKey, staticClass: "organ-key-value" }, [
              _c("label", { staticClass: "label-key" }, [
                _vm._v(_vm._s(_vm.organChineseMap[organKey])),
              ]),
              _c("label", { staticClass: "label-value" }, [
                !_vm.notEditedInfos.includes(organKey)
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.organInfo[organKey],
                          expression: "organInfo[organKey]",
                        },
                      ],
                      staticClass: "input-value",
                      attrs: { type: "text" },
                      domProps: { value: _vm.organInfo[organKey] },
                      on: {
                        blur: function ($event) {
                          return _vm.showCourtInfo(organKey)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.organInfo, organKey, $event.target.value)
                        },
                      },
                    })
                  : _c("label", { staticClass: "input-value" }, [
                      _vm._v(_vm._s(_vm.organInfo[organKey])),
                    ]),
              ]),
            ])
          }),
          _c(
            "van-popup",
            {
              staticClass: "court-dialog",
              attrs: { position: "bottom" },
              model: {
                value: _vm.isShowCourtsDialog,
                callback: function ($$v) {
                  _vm.isShowCourtsDialog = $$v
                },
                expression: "isShowCourtsDialog",
              },
            },
            _vm._l(_vm.courts, function (court, courtIndex) {
              return _c(
                "p",
                {
                  key: courtIndex,
                  staticClass: "court-type",
                  on: {
                    click: function ($event) {
                      return _vm.chooseCourt(court)
                    },
                  },
                },
                [_vm._v(_vm._s(court.name))]
              )
            }),
            0
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }